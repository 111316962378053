





























































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Add16 from '@carbon/icons-vue/es/add/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, numeric } from 'vee-validate/dist/rules'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import PermissionsMixin from '@/mixins/permissionsMixin'
import TopicsComboBox from '@/partials/forms/TopicsComboBox.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import ImageModalDelete from '@/partials/modals/Questions/Drafts/ImageDelete.vue'
import ApproveQuestionModal from '@/partials/modals/Questions/Drafts/Approve.vue'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import FileUploader from '@/partials/forms/components/FileUploader.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  directives: { mask },
  components: {
    Settings16,
    TrashCan16,
    Add16,
    ArrowUp16,
    ArrowDown16,
    TopicsComboBox,
    ValidationObserver,
    ValidationProvider,
    ImageModalDelete,
    ApproveQuestionModal,
    NotifyOnFailed,
    FileUploader,
    PasswordConfirmField
  }
})
export default class QuestionsDraftsEdit extends Mixins(PermissionsMixin, FlashMessageMixin) {
  options = [
    {
      option: '',
      key: 1,
      correct: false
    },
    {
      option: '',
      key: 2,
      correct: false
    },
    {
      option: '',
      key: 3,
      correct: true
    },
    {
      option: '',
      key: 4,
      correct: false
    }
  ]

  globalKey = 5

  optionsType = 'radio'

  form: Record<string, any> = {
    code: '',
    type: 'radio',
    level: '50',
    points: 1,
    time: 60,
    body: '',
    justification: '',
    justificationFile: '',
    video: '',
    topics: [],
    file: '',
    confirm: '',
    errors: {
      code: '',
      confirm: ''
    }
  }

  time = {
    minutes: 0,
    seconds: 0
  }

  topics = []

  image: any = {}
  justificationImage: any = {}
  imageToDelete = {}

  removeImageFromScreen (imageId: string) {
    if (this.image?.id === imageId) {
      this.image = null
    } else if (this.justificationImage?.id === imageId) {
      this.justificationImage = null
    }
  }

  reviewer = {}

  created () {
    this.validations()
    axios.get(`question/${this.$route.params.id}`)
      .then(response => {
        const responseData = camelCaseKeys(response.data.data, { deep: true })
        this.form = {
          code: responseData.code,
          type: responseData.type,
          level: responseData.level.toString(),
          points: responseData.points,
          time: responseData.time,
          body: responseData.body,
          justification: responseData.justification,
          video: responseData.video,
          topics: responseData.topics.map((topic: Topic) => topic.id),
          file: '',
          justificationFile: '',
          errors: {
            code: '',
            confirm: ''
          }
        }

        this.topics = responseData.topics
        this.image = responseData.file
        this.justificationImage = responseData.justificationFile
        this.reviewer = responseData.reviewer

        this.time.minutes = Math.floor(responseData.time / 60)
        this.time.seconds = responseData.time % 60

        if (responseData.type === 'radio') {
          this.globalKey = 1
          this.options = responseData.options.map((option: QuestionOption) => {
            return {
              option: option.option,
              key: this.globalKey++,
              correct: option.correct
            }
          })
        }
      })
  }

  done = true
  submitedInvalid = false

  submit () {
    this.done = false
    const formOptions = this.options.map((option, index) => {
      return { option: option.option, order: index, type: this.optionsType, correct: option.correct }
    })

    const textOptions = [
      {
        type: 'text',
        order: 1,
        option: 'text',
        correct: false
      }
    ]

    const submitForm = snakeCaseKeys({
      ...this.form,
      options: this.form.type === 'radio' ? formOptions : textOptions,
      time: this.time.minutes * 60 + this.time.seconds
    })

    axios.put(`question/${this.$route.params.id}`, submitForm)
      .then(() => {
        this.$router.push({ name: 'QuestionsDrafts' }, () => {
          const flashMessage = {
            message: 'Questão de rascunho editada com sucesso.',
            isSuccess: true
          }
          this.setFlashMessage(flashMessage)
        })
      })
      .catch(err => {
        const response = err.response
        const errors = response.data.errors
        if (errors) {
          const errors = camelCaseKeys(response.data.errors)
          this.submitedInvalid = true

          Object.entries(errors).map(([key, value]: [string, any]) => {
            this.form.errors[key] = value[0]
          })
        }
      })
      .finally(() => { this.done = true })
  }

  removeImageModalIsOpened = false
  approveQuestionModalIsOpened = false

  validations () {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required.'
    })
    extend('numeric', {
      ...numeric,
      message: 'The {_field_} field is required.'
    })
  }

  makeOptionsIncorrect () {
    this.options = this.options.map(option => {
      option.correct = false
      return option
    })
  }

  removeChoice (index: number) {
    if (this.options.length > 2) {
      if (this.options[index]?.correct) {
        this.options[0].correct = true
      }
      this.options.splice(index, 1)
    }
  }

  addChoice (index: number) {
    const choice = { option: '', key: this.globalKey++, correct: false }
    this.options.splice(index, 0, choice)
  }

  moveChoice (index: number, direction: string) {
    let targetIndex: number

    if (direction === 'up') {
      targetIndex = index - 1
    } else {
      targetIndex = index + 1
    }

    const swappingChoice = this.options[targetIndex]

    this.options.splice(targetIndex, 1)
    this.options.splice(index, 0, swappingChoice)
  }
}
