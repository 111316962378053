
































import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    PasswordConfirmField,
    ValidationObserver
  }
})
export default class QuestionDraftImageDelete extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop() file!: QuestionImageFile

  form: Record<string, any>= {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  closeModal () {
    this.$emit('close')
  }

  submit () {
    axios.delete(`question/${this.$route.params.id}/file/${this.file.id}`, { data: this.form })
      .then(response => {
        this.$emit('submitted', response.data.data)
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
